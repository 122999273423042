import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div>
            <p>&copy; 2023 Atlantix Commodities. All rights reserved.</p>
          </div>
          <div>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <button onClick={() => alert('Privacy Policy')} className="text-gray-300 hover:text-white mr-4">Privacy Policy</button>
            <button onClick={() => alert('Terms of Service')} className="text-gray-300 hover:text-white mr-4">Terms of Service</button>
            <button onClick={() => alert('Contact Us')} className="text-gray-300 hover:text-white">Contact Us</button>
            {/* eslint-enable jsx-a11y/anchor-is-valid */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
