export const users = [
    {
      id: 1,
      name: 'John Doe',
      email: 'john@example.com',
      password: 'password123',
      role: 'user',
      avatar: '/placeholder.svg?height=32&width=32',
    },
    {
      id: 2,
      name: 'Admin User',
      email: 'admin@example.com',
      password: 'admin123',
      role: 'admin',
      avatar: '/placeholder.svg?height=32&width=32',
    },
  ];
  
  export const contracts = [
    { id: 'ATX-001', commodity: 'Almonds', status: 'Pending', fulfillmentDate: '2023-07-15' },
    { id: 'ATX-002', commodity: 'Dried Apricots', status: 'Fulfilled', fulfillmentDate: '2023-06-30' },
    { id: 'ATX-003', commodity: 'Cashews', status: 'In Progress', fulfillmentDate: '2023-08-01' },
  ];
  
  export const products = [
    { id: 1, name: 'Organic Almonds', category: 'Nuts', origin: 'California, USA', price: 9.99 },
    { id: 2, name: 'Dried Apricots', category: 'Dried Fruits', origin: 'Turkey', price: 6.99 },
    { id: 3, name: 'Raw Cashews', category: 'Nuts', origin: 'Vietnam', price: 11.99 },
    { id: 4, name: 'Medjool Dates', category: 'Dried Fruits', origin: 'Israel', price: 8.99 },
  ];