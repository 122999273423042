import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Search } from 'lucide-react';
import { products } from '../utils/dummyData';
import { formatCurrency } from '../utils/formatters';

const Products = () => {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-bold">Products</h1>
        <div className="flex items-center space-x-2">
          <Input 
            type="text" 
            placeholder="Search products..." 
            className="w-64"
          />
          <Button variant="outline">
            <Search className="h-4 w-4 mr-2" />
            Search
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map((product) => (
          <Card key={product.id}>
            <CardHeader>
              <CardTitle>{product.name}</CardTitle>
            </CardHeader>
            <CardContent>
              <p><strong>Category:</strong> {product.category}</p>
              <p><strong>Origin:</strong> {product.origin}</p>
              <p><strong>Price:</strong> {formatCurrency(product.price)}/lb</p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Products;