import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Textarea } from "../components/ui/textarea";

const Support = () => {
  return (
    <div className="space-y-6">
      <h1 className="text-3xl font-bold">Support</h1>

      <Card>
        <CardHeader>
          <CardTitle>Contact Support</CardTitle>
        </CardHeader>
        <CardContent>
          <form className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
              <Input type="text" id="name" name="name" required />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <Input type="email" id="email" name="email" required />
            </div>
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700">Subject</label>
              <Input type="text" id="subject" name="subject" required />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
              <Textarea id="message" name="message" rows={4} required />
            </div>
            <Button type="submit">Submit</Button>
          </form>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>FAQs</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div>
              <h3 className="font-semibold">How do I track my order?</h3>
              <p>You can track your order by logging into your account and visiting the 'Orders' section.</p>
            </div>
            <div>
              <h3 className="font-semibold">What payment methods do you accept?</h3>
              <p>We accept major credit cards, PayPal, and bank transfers for large orders.</p>
            </div>
            <div>
              <h3 className="font-semibold">How can I request a sample?</h3>
              <p>To request a sample, please contact our sales team through the form above or call us directly.</p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Support;