import React from 'react';
import AdminDashboard from '../components/admin/AdminDashboard';


const AdminPortal = () => {
  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Admin Portal</h1>
      <AdminDashboard />
    </div>
  );
};

export default AdminPortal;