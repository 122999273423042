import React from 'react';
import { Link } from 'react-router-dom';
import { Home, FileText, Package, HelpCircle, Settings } from 'lucide-react';

const Sidebar = () => {
  return (
    <aside className="bg-gray-800 text-white w-64 min-h-screen p-4">
      <nav>
        <ul className="space-y-2">
          <li>
            <Link to="/" className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-700">
              <Home className="h-5 w-5" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/contracts" className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-700">
              <FileText className="h-5 w-5" />
              <span>Contracts</span>
            </Link>
          </li>
          <li>
            <Link to="/products" className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-700">
              <Package className="h-5 w-5" />
              <span>Products</span>
            </Link>
          </li>
          <li>
            <Link to="/support" className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-700">
              <HelpCircle className="h-5 w-5" />
              <span>Support</span>
            </Link>
          </li>
          <li>
            <Link to="/settings" className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-700">
              <Settings className="h-5 w-5" />
              <span>Settings</span>
            </Link>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;