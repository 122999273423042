import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import Layout from '../components/common/Layout';

const Home = () => {
  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8">Welcome to Atlantix Commodities</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Card>
            <CardHeader>
              <CardTitle>Client Portal</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-4">Access your contracts, products, and more.</p>
              <Link to="/client-portal">
                <Button>Enter Client Portal</Button>
              </Link>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Admin Portal</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-4">Manage users, contracts, and products.</p>
              <Link to="/admin-portal">
                <Button>Enter Admin Portal</Button>
              </Link>
            </CardContent>
          </Card>
        </div>
      </div>
    </Layout>
  );
};

export default Home;