import React from 'react';
import ClientDashboard from '../components/client/ClientDashboard';

const ClientPortal = () => {
  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Client Portal</h1>
      <ClientDashboard />
    </div>
  );
};

export default ClientPortal;